import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contactus = () => {
    // State to manage form data
    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        user_message: '',
    });

    // State for email validation
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        AOS.init({
            duration: 2500,
        });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateEmail = () => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.user_email);
        setEmailError(!isValid);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate email before submitting
        if (!validateEmail()) {
            return;
        }

        try {
            const templateParams = {
                name: formData.user_name,
                email: formData.user_email,
                message: formData.user_message,
            };

            const emailResult = await emailjs.send(
                'service_79v7s1e',
                'template_h8212pe',
                templateParams,
                'vPZ0puqM-a98bTeRM'
            );

            if (emailResult.text === 'OK') {
                console.log('Email sent successfully');

                // Clear form data after successful submission
                setFormData({
                    user_name: '',
                    user_email: '',
                    user_message: '',
                });

                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Email sent successfully!',
                });
            } else {
                console.error('Failed to send email:', emailResult.text);
                // Handle the error, show an error message to the user, etc.
            }
        } catch (error) {
            console.error('Error sending email:', error);
            // Handle any unexpected errors
        }
    };

    return (
        <>
            <section class="page-header contact-page">
                <div class="container">
                    <div class="row py-5">
                        <div class="col-8 mx-auto text-center">
                            <ul class="breadcrumbs" style={{ transform: 'translateY(130px)' }}>
                                <li class="list-inline-item"><a href="/" style={{ color: 'rgb(0, 0, 0)' }}>Home</a></li>
                                <li class="list-inline-item">Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            {/* page-header end */}

            <section class="section" id="targetSection">
                <div class="container">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-lg-6">
                            <div class="section-title text-center">
                                <br />
                                <h1 class="text-black aos-init aos-animate" data-aos="fade-left">Let's get connected</h1>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="shadow rounded p-5 bg-white">
                                <div class="row">
                                    <div class="col-md-12 mb-4 aos-init" data-aos="fade-right"><h4>Leave Us A Message</h4></div>
                                    <div class="col-lg-8 aos-init" data-aos="fade-up">
                                        <div class="contact-form">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group mb-4 pb-2">
                                                    <label htmlFor="contact_name" className="form-label">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control shadow-none"
                                                        id="contact_name"
                                                        name="user_name"
                                                        required=""
                                                        autoComplete="off"
                                                        value={formData.user_name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-4 pb-2">
                                                    <label htmlFor="contact_email" className="form-label">
                                                        Email address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className={`form-control shadow-none ${emailError ? 'is-invalid' : ''}`}
                                                        id="contact_email"
                                                        name="user_email"
                                                        required=""
                                                        autoComplete="off"
                                                        value={formData.user_email}
                                                        onChange={handleInputChange}
                                                    />
                                                    {emailError && <div className="invalid-feedback">Please enter a valid email address.</div>}
                                                </div>
                                                <div className="form-group mb-4 pb-2">
                                                    <label htmlFor="user_message" className="form-label">
                                                        Write Message
                                                    </label>
                                                    <textarea
                                                        className="form-control shadow-none"
                                                        id="user_message"
                                                        rows="3"
                                                        name="user_message"
                                                        required=""
                                                        value={formData.user_message}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <button type="submit" className="btn-primary w-100">
                                                    Send Message
                                                </button>
                                            </form>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 mt-5 mt-lg-0 aos-init" data-aos="fade-left">
                                        <div class="contact-info">
                                            <div class="block mt-0">
                                                <h4 class="h5">Still Have Questions?</h4>
                                                <div class="content">
                                                    Call Us We Will Be Happy To Help<br />
                                                    <a href="tel:+919654234208">+91 9654234208</a><br />
                                                    Monday - Saturday<br />
                                                    10:00 AM TO 06:00 PM IST
                                                </div>
                                            </div>
                                            <div class="block mt-4">
                                                <h4 class="h5">E-mail Us</h4>
                                                <div class="content"><a href="mailto:connect@venturesgrow.com">connect@venturesgrow.com</a></div>
                                            </div>
                                            <div class="block mt-4">
                                                <h4 class="h5">Meet Us</h4>
                                                <div class="content">
                                                    B-36, 2nd Floor<br />
                                                    Sector-67, Noida<br />
                                                    Uttar Pradesh - 201301
                                                </div>
                                            </div>
                                            <div class="block">
                                                <ul class="list-unstyled list-inline mt-2 mb-0 social-icons">
                                                    <li class="list-inline-item me-3">
                                                        <a class="text-black facebook text-decoration-none" target="_blank" rel="noreferrer" href="https://www.facebook.com/venturesgrow"><i class="fab fa-facebook-f text-decoration-none"></i></a>
                                                    </li>
                                                    <li class="list-inline-item me-3">
                                                        <a class="text-black twitter text-decoration-none" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/venturesgrow/"><i class="fa-brands fa-linkedin-in"></i></a>
                                                    </li>
                                                    <li class="list-inline-item me-3">
                                                        <a class="text-black instagram text-decoration-none" target="_blank" rel="noreferrer" href="https://www.instagram.com/venturesgrow_/"><i class="fab fa-instagram"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </>
    )
}

export default Contactus