import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainPage from './pages/Mainpage';
import Aboutus from './pages/Aboutus';
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contactus from './pages/Contactus';
import Footer from './components/Footer';
import Career from './components/Career';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import Termscondition from './pages/Termscondition.jsx';
import LoadingSpinner from './components/LoadingSpinner';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data) with setTimeout
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false); // Set loading to false after 2 seconds
    };

    fetchData();
  }, []);

  return (
    <Router>
      {loading ? (
        <LoadingSpinner /> // Display the preloader while loading is true
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contactus />} />
            <Route path="/career" element={<Career />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditons" element={<Termscondition />} />
          </Routes>
          <Footer /> {/* Render the footer once loading is complete */}
        </>
      )}
    </Router>
  );
}

export default App;
